import React from "react";

const Triangular = ({
    viewBox = '0 0 24 24',
    color = '#999',
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1250 1024"
    p-id="16634"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M102.4 838.050909a103.098182 103.098182 0 0 1-102.167273-102.4V102.4A102.4 102.4 0 0 1 153.832727 13.963636l548.305455 316.509091a102.4 102.4 0 0 1 0 177.338182L153.6 824.32a102.167273 102.167273 0 0 1-51.2 13.730909z"
      fill={color}
      p-id="16635"
    ></path>
  </svg>
);


export default Triangular;