import React, { Component } from "react";
import "../style/productDetail.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { getProductDetailById, getRandomElements } from "../apis/GetProduct";
import Truck from "../icon/Truck";
import Time from "../icon/Time";
import Number from "./common/Number";
import Rating from "@material-ui/lab/Rating";
import ShoppingCart from "../icon/ShoppingCart";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      isActive: 0,
      randomList: [],
    };
    this.clickChangeImage = this.clickChangeImage.bind(this);
    this.clickAddToCart = this.clickAddToCart.bind(this);
    this.routePage = this.routePage.bind(this);
  }
  headerRef = React.createRef();
  // numberRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    let { location } = this.props;
    let id = location.pathname.replace("/ProductDetail/", "");
    let product = getProductDetailById(parseInt(id));
    this.setState({ product });
  }

  clickChangeImage(index) {
    this.setState({ isActive: index });
  }

  clickAddToCart(product) {
    let isProductInCart = false;
    let quantity = 0;
    if (this.numberRef) {
      product.quantity = this.numberRef.current.state.num;
    }

    if (this.headerRef) {
      let cartList = this.headerRef.current.state.cartList;

      if (cartList.length <= 0) {
        cartList.push(product);
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].id === product.id) {
            cartList[i].quantity += product.quantity;
            cartList[i].total = (
              cartList[i].quantity * parseFloat(product.price)
            ).toFixed(2);
            isProductInCart = true;
            break;
          }
        }

        if (!isProductInCart) {
          cartList.push(product);
        }
      }

      cartList.forEach((item) => {
        quantity += item.quantity;
      });

      this.headerRef.current.setState({ cartList, quantity });
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
      this.routePage("checkout");
    }
  }

  hoverChange(index) {
    let { randomList } = this.state;
    randomList[index].isHover = !randomList[index].isHover;
    this.setState({ randomList });
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      case "checkout":
        history.push("/Checkout");
        break;
      default:
        break;
    }
  }

  render() {
    let { product, isActive } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="productDetail-page">
          <div className="productDetail">
            <div className="row1">
              <div className="left-box">
                <div className="lit-title">
                  <div className="tip">{product.tip1}</div>
                  <div className="tip">{product.tip2}</div>
                </div>
                <div className="title">{product.title}</div>
                <div className="lit-title">
                  <div className="desc-tip">対面</div>
                  <div className="desc-tip">オンライン</div>
                </div>
                <p>
                  【日本初】あがり症がセミナーで治るもんか。「１人6回」ものスピーチで成功体験を積め。
                </p>
                <div className="star">
                  <div className="left">
                    <Rating
                      name="half-rating-read"
                      precision={0.1}
                      value={5}
                      readOnly
                      size="medium"
                    />
                    <div className="tip">レビュー{product.a}件</div>
                  </div>
                  <p>受けた人{product.b}人 </p>
                  <p>開催回数{product.c}回</p>
                </div>
                <div className="big-img">
                  <img src={product.image && product.image[isActive]} alt="" />
                </div>
                <div className="row2">
                  {product.description &&
                    product.description.map((item, index) => {
                      return (
                        <div className="left-img" key={index}>
                          <div className="col">
                            <div className="title">{item.title}</div>
                            {item.desc &&
                              item.desc.map((item, index) => {
                                return (
                                  <p className="desc" key={index}>
                                    {item}
                                  </p>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="right-box">
                <div className="sku">
                  <p>{product.date}</p>
                  <p>{product.times}</p>
                  <p>{product.teacher}</p>
                </div>
                <p className="title">予約締切日時</p>
                <p>{product.date}</p>
                <p className="title">参加人数</p>
                <p>{product.people}人</p>
                <p className="title">開催場所</p>
                <p>{product.address}</p>
                <p className="title">価格(税込)</p>
                <p>¥{product.price}</p>
                <p className="desc">{product.tip}</p>
                <div className="btn-row">
                  <div
                    className="addToCart"
                    onClick={() => this.clickAddToCart(product)}
                  >
                    予約へ進む
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
