import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import AddIcon from "../icon/AddIcon";
import sec1 from "../image/sec1.png";
import sec1_mask from "../image/sec1-mask.png";
import sec2 from "../image/sec2.png";
import sec3 from "../image/sec3.png";
import sec3_1 from "../image/sec3-1.png";
import sec3_2 from "../image/sec3-2.png";
import sec3_3 from "../image/sec3-3.png";
import sec3_4 from "../image/sec3-4.png";
import sec5 from "../image/sec5.png";
import Rating from "@material-ui/lab/Rating";
import sec6 from "../image/sec6.jpg";
import sec6_1 from "../image/sec6-1.jpg";
import logo from "../image/logo.png";

import sec1_bg from "../image/sec1-bg.svg";
import sec2_bg from "../image/sec2-bg.svg";
import sec3_bg from "../image/sec3-bg.svg";
import sec4_bg from "../image/sec4-bg.svg";
import sec5_bg from "../image/sec5-bg.svg";
import sec6_bg from "../image/sec6-bg.svg";
import Triangular from "../icon/Triangular";
import sec7 from "../image/sec7.png";
import sec7_1 from "../image/sec7-1.png";
import sec7_2 from "../image/sec7-2.png";
import sec7_3 from "../image/sec7-3.png";
import sec7_4 from "../image/sec7-4.png";
import sec7_5 from "../image/sec7-5.png";
import sec7_6 from "../image/sec7-6.png";
import sec7_7 from "../image/sec7-7.png";
import sec7_8 from "../image/sec7-8.png";
import sec7_9 from "../image/sec7-9.png";
import sec7_10 from "../image/sec7-10.png";
import sec7_11 from "../image/sec7-11.png";
import sec7_12 from "../image/sec7-12.png";
import sec7_13 from "../image/sec7-13.png";
import sec7_14 from "../image/sec7-14.png";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <div className="left">
              <img src={sec1} alt="" />
              <div className="mask">
                <img src={sec1_mask} alt="" />
              </div>
              <div className="contain">
                <div className="title">
                  受講満足度 <span>98.1</span>%※1 <br />{" "}
                  コスパ最強の習いごとなら
                </div>
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <div className="row">
                  <div className="col">
                    <p>累計受講者数 ※2</p>
                    <p>
                      <span>152 </span> 万人
                    </p>
                  </div>
                  <div className="col">
                    <p>掲載講座数</p>
                    <p>
                      <span>8.5</span>万件
                    </p>
                  </div>
                </div>
                {/* <div className="row btn">新規ユーザー登録へ（無料）</div> */}
              </div>
            </div>
            <div className="bg">
              <img src={sec1_bg} alt="" />
            </div>
          </div>
          <div className="sec2">
            <div className="row">
              <div className="col">
                <img src={sec2} alt="" />
              </div>
              <div className="col">
                ストアカは <br />
                <div className="tl1">1回から気軽にレッスンを検索・予約</div>
                できる
                <br /> 日本最大級の習いごと検索サービスです
              </div>
            </div>
            <div className="bg">
              <img src={sec2_bg} alt="" />
            </div>
          </div>
          <div className="sec3">
            <p>“おしえる・まなぶ”を通じ、新たな一歩を踏み出しています！</p>
            <div className="title">User’s Voice</div>
            <div className="row1">
              <div className="col">
                ボクササイズやファスティングでリモートワークで激増した体重を、8kgも落とすことができました！
                <div className="img">
                  <img src={sec3} alt="" />
                  マレフィセントさん
                </div>
              </div>
              <div className="col">
                <div className="img">
                  <img src={sec3_1} alt="" />
                  ちまさん
                </div>
                DX化に乗り遅れないよう、Webマーケをリスキリングして転職＆副業に成功しました。
              </div>
              <div className="col">
                内気な性格の小学生の娘が、絵のレッスンを通じて自信をつけてお友達が増えました。
                <div className="img">
                  <img src={sec3_2} alt="" />
                  sumidaさん
                </div>
              </div>
            </div>
            <div className="row2">
              <div className="col">
                <div className="img">
                  <img src={sec3_3} alt="" />
                  Mさん
                </div>
                講座で知り合った方と意気投合。なんとお笑いコンビを組んで、M-1グランプリに出場しました！
              </div>
              <div className="col">
                機械オンチでしたが、オンライン料理教室に思い切って挑戦！気軽に始めたつもりが今ではすっかりハマっています。
                <div className="img">
                  <img src={sec3_4} alt="" />
                  クーさん
                </div>
              </div>
            </div>
            <div className="bg">
              <img src={sec3_bg} alt="" />
            </div>
          </div>
          <div className="sec4">
            <div className="title">
              気軽に始められる<span>3</span>つの特徴
            </div>
            <div className="row">
              <div className="col">
                入会金・登録料 <span>無料!</span>
              </div>
              <div className="col">
                検索〜予約〜決済まで <span>オンラインで完結！</span>
              </div>
              <div className="col">
                充実のオンラインレッスンで
                <span>好きな時間・場所で 楽しめる！</span>
              </div>
            </div>
            <div className="bg">
              <img src={sec4_bg} alt="" />
            </div>
          </div>
          <div className="sec5">
            <div className="contain">
              <div className="col">
                <img src={sec5} alt="" />
              </div>
              <div className="col">
                <p>
                  たった<span>3</span>ステップで、カンタン予約♪
                </p>
                <div className="row">
                  <div className="yuan">無料登録</div>
                  <Triangular size={20} color="#5FA5F0" />
                  <div className="yuan">探す</div>
                  <Triangular size={20} color="#5FA5F0" />
                  <div className="yuan">予約</div>
                </div>
              </div>
            </div>
            <div className="bg">
              <img src={sec5_bg} alt="" />
            </div>
          </div>
          <div className="sec6">
            <div className="title">
              <span>対面</span>でも、<span>オンライン</span> でも。
            </div>
            <div className="title2">
              あなたのライフスタイルに合わせて習いごとを楽しめる！
            </div>
            <div className="contain">
              <div className="col">
                <div className="img">
                  <img src={sec6} alt="" />
                </div>
                <div className="txt-box">
                  <div className="tit">対面講座</div>
                  <p>
                    先生から直接学びたい、他の参加者との交流を楽しみたいなら、対面講座で。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="img">
                  {" "}
                  <img src={sec6_1} alt="" />
                </div>
                <div className="txt-box">
                  <div className="tit">オンライン講座</div>
                  <p>
                    好きな時間・場所で、自分のペースで好きなことを続けたいなら、オンライン講座で。
                  </p>
                </div>
              </div>
            </div>
            <div className="bg">
              <img src={sec6_bg} alt="" />
            </div>
          </div>
          <div className="sec7">
            <div className="title">
              あなたの<span>やってみたい</span>が見つかる
            </div>
            <div className="row">
              <div className="col">
                <div className="box1">
                  <img src={sec7} alt="" />
                  <div className="tip">料理</div>
                </div>
                <div className="box1">
                  <img src={sec7_1} alt="" />
                  <div className="tip">占い</div>
                </div>
                <div className="box1">
                  <img src={sec7_3} alt="" />
                  <div className="tip">コミュニケーションスキル</div>
                </div>
                <div className="box1">
                  <img src={sec7_5} alt="" />
                  <div className="tip">デッサン</div>
                </div>
                <div className="box1">
                  <img src={sec7_7} alt="" />
                  <div className="tip">ヨガ・ダンス</div>
                </div>
                <div className="box1">
                  <img src={sec7_9} alt="" />
                  <div className="tip">フラワーアレンジメント</div>
                </div>
                <div className="box1">
                  <img src={sec7_11} alt="" />
                  <div className="tip">SNSマーケティング</div>
                </div>
                <div className="box1">
                  <img src={sec7_13} alt="" />
                  <div className="tip">子どもの習い事</div>
                </div>
              </div>
              <div className="col">
                <div className="box1">
                  <img src={sec7_2} alt="" />
                  <div className="tip">メイク</div>
                </div>
                <div className="box1">
                  <img src={sec7_4} alt="" />
                  <div className="tip">ライティング</div>
                </div>
                <div className="box1">
                  <img src={sec7_6} alt="" />
                  <div className="tip">動画編集</div>
                </div>
                <div className="box1">
                  <img src={sec7_8} alt="" />
                  <div className="tip">写真</div>
                </div>
                <div className="box1">
                  <img src={sec7_10} alt="" />
                  <div className="tip">英会話</div>
                </div>
                <div className="box1">
                  <img src={sec7_12} alt="" />
                  <div className="tip">お菓子づくり</div>
                </div>
                <div className="box1">
                  <img src={sec7_14} alt="" />
                  <div className="tip">Webデザイン</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec8">
            <div className="title">全国の対面講座</div>
            <div className="row">
              {productList.map((item, index) => {
                return (
                  <div className="col" key={index} onClick={()=>this.routeProductDetail(item.id)}>
                    <div className="img">
                      <img src={item.image[0]} alt="" />
                    </div>
                    <div className="title">{item.title}</div>
                    <div className="price">
                      ¥{item.price}
                      <span> {item.time} </span>
                    </div>
                    <div className="time">{item.date} 他日程有</div>
                    <div className="des">{item.teacher} / オンライン</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
