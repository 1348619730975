import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import sec6_people from "../image/sec6-people.jpg";
import contact from "../image/contact.png";
import contact1 from "../image/contact1.jpg";
import contact2 from "../image/contact2.png";
import contact3 from "../image/contact3.jpg";
import contact4 from "../image/contact4.png";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <div className="left">
            <div className="title">ストアカの特徴</div>
            <div className="card">
              <div className="left">
                <img src={contact} alt="" />
              </div>
              <div className="right">
                <h3>無料で簡単に、始められる</h3>
                <p>
                  初期費用、月額費用は無料。
                  <br />
                  専門知識がなくてもだれでも簡単にあなただけの予約ページが作れます。
                  まずは気軽にスキルシェアを始めてみましょう。
                </p>
              </div>
            </div>
            <div className="card">
              <div className="right">
                <h3>すぐに生徒と出会える 登録者数82万人</h3>
                <p>
                  ストアカは教えたい人と学びたい人が集まるまなびのコミュニティ。
                  ビジネススキルから趣味の習い事まで、あなたのスキルを学びたい生徒とすぐに出会うことができます。あなたの経験や知識といったスキルはきっと誰かの役に立つはずです。
                </p>
              </div>
              <div className="left">
                <img src={contact1} alt="" />
              </div>
            </div>
            <div className="card">
              <div className="left">
                <img src={contact2} alt="" />
              </div>
              <div className="right">
                <h3>あらゆる開催形式でサービスを販売できる</h3>
                <p>
                  単発のレッスンはもちろんのこと、コースレッスン、コーチング、コンサルティング、月額課金のサービスまで、ニーズにあわせて多様な形式でサービスを提供することが可能です。
                </p>
              </div>
            </div>
            <div className="card">
              <div className="right">
                <h3>オンライン開催も簡単</h3>
                <p>
                  講座はオンラインでも対面でも開催自由。
                  zoom（ズーム）との連携機能で、接続URLを自動で発行、参加者にも通知されるので、オンラインでの開催も簡単です。
                </p>
              </div>
              <div className="left">
                <img src={contact3} alt="" />
              </div>
            </div>
            <div className="card">
              <div className="left">
                <img src={contact4} alt="" />
              </div>
              <div className="right">
                <h3>そして、安心・安全</h3>
                <p>
                  先生と生徒が出会うからこそ、安心安全はなにより大切です。
                  講座中のもしもの事故にもストアカ独自の補償制度が先生や生徒のみなさまをお守りします。{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
