import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import aboutImg from "../image/about.png";
import aboutBg from "../image/about-bg.png";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="about-head">
            <img src={aboutBg} alt="" />
            <p className="title">ストアカとは</p>
          </div>
          <div className="sec1">
            <img src={aboutImg} alt="" />
          </div>
          <div className="about-content">
            <div className="head-contact">
              <p>ストアカでは、満足度の高い先生からまなぶことができます。</p>
              <p>受講者満足度98.1%*、口コミやレビューも充実しているから、</p>
              <p>安心してオンライン・対面のレッスンを受けられます。 入会金は一切かかりません。</p>
              <p>講座形式は、オンライン形式と対面形式のどちらにも対応し、</p>
              <p>自分に合った学びのスタイルを見つけられます。</p>
              <p>またご自身の得意なことや専門的なスキルを誰かに教えたい方は、</p>
              <p>ぜひ「先生」として講座を開催しませんか。</p>
              <p>※1 2023年1月〜2023年12月に投稿された全口コミの平均満足度</p>
              <p>※2 延べ人数</p>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
